import { isFSA, isError } from "../../helpers/flux-standard-action"
import { LOGIN_FINISHED } from "./login"

export const UPDATE_EMAIL_VALIDATION = "signup/UPDATE_EMAIL_VALIDATION"
export const CREATE_ACCOUNT_REQUESTED = "signup/CREATE_ACCOUNT_REQUESTED"
export const CREATE_ACCOUNT_FINISHED = "signup/CREATE_ACCOUNT_FINISHED"

export const RESULT_OK = "ok"
export const RESULT_ADDRESS_IN_USE = "address-in-use"
export const RESULT_NOT_ACTIVATED = "not-activated-yet"

export const requestCreateAccount = (email, language) => {
  return {
    type: CREATE_ACCOUNT_REQUESTED,
    payload: {
      email,
      language,
    },
  }
}

export const finishCreateAccount = createAccountResult => {
  return {
    type: CREATE_ACCOUNT_FINISHED,
    payload: createAccountResult,
  }
}

export const updateEmailValidation = validationResult => {
  return {
    type: UPDATE_EMAIL_VALIDATION,
    payload: validationResult,
  }
}

const initialState = {
  isEmailValid: false,
  isAddressInUse: false,
  isWaitingForValidation: false,
}

export const reducer = (state = initialState, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case UPDATE_EMAIL_VALIDATION: {
      return { ...state, isEmailValid: action.payload.isValid }
    }
    case CREATE_ACCOUNT_FINISHED: {
      return {
        ...state,
        isWaitingForValidation: action.payload.isWaitingForValidation || false,
        isAddressInUse: action.payload.isAddressInUse || false,
      }
    }
    case LOGIN_FINISHED:
      if (isError(action) && action.payload === RESULT_NOT_ACTIVATED) {
        return state
      }
      // after the login procedure finishes (successful or not) we have to reset the state
      // so the customer could start over with a fresh UI.
      return initialState
    default:
      break
  }

  return state
}
