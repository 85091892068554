import { isFSA } from "../../helpers/flux-standard-action"
import {
  UPDATE_FILE_STATS,
  SET_EMAIL,
  SET_SHAREMETHOD,
  START_NEW_UPLOAD,
} from "./upload"

export const READ_WELCOME_TOUR_STEP = "help/READ_WELCOME_TOUR_STEP"
export const SET_WELCOME_TOUR_STEP = "help/SET_WELCOME_TOUR_STEP"

export const readWelcomeTourStep = () => {
  return {
    type: READ_WELCOME_TOUR_STEP,
  }
}

export const setWelcomeTourStep = step => {
  return {
    type: SET_WELCOME_TOUR_STEP,
    payload: step,
  }
}

export const WELCOME_TOUR_FINISHED = 4

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case UPDATE_FILE_STATS:
      if (state.welcome < 2) {
        return { ...state, welcome: 2 }
      }
      break
    case SET_EMAIL:
      const email = action.payload
      if (email && email.recipients.length > 0) {
        return {
          ...state,
          emailHasRecipients: true,
          welcome:
            state.welcome < WELCOME_TOUR_FINISHED ? 3 : WELCOME_TOUR_FINISHED,
        }
      } else {
        return {
          ...state,
          emailHasRecipients: false,
          welcome:
            state.welcome < WELCOME_TOUR_FINISHED ? 2 : WELCOME_TOUR_FINISHED,
        }
      }
    case SET_SHAREMETHOD:
      const shareMethod = action.payload
      if (shareMethod !== "email" || state.emailHasRecipients) {
        return {
          ...state,
          welcome:
            state.welcome < WELCOME_TOUR_FINISHED ? 3 : WELCOME_TOUR_FINISHED,
        }
      } else {
        return {
          ...state,
          welcome:
            state.welcome < WELCOME_TOUR_FINISHED ? 2 : WELCOME_TOUR_FINISHED,
        }
      }
    case START_NEW_UPLOAD:
      return {
        ...state,
        emailHasRecipients: false,
        welcome:
          state.welcome < WELCOME_TOUR_FINISHED ? 1 : WELCOME_TOUR_FINISHED,
      }
    case SET_WELCOME_TOUR_STEP:
      return {
        ...state,
        welcome: action.payload,
      }
    default:
      break
  }

  return (
    state || {
      emailHasRecipients: false,
      welcome: 1,
    }
  )
}
