import { isFSA } from "../../helpers/flux-standard-action"

export const SET_PASSWORD_SENT = "resetpassword/SET_PASSWORD_SENT"
export const SET_IS_ADDRESS_NOT_REGISTERED = "resetpassword/SET_IS_ADDRESS_NOT_REGISTERED"
export const RESET_FORM = "resetpassword/RESET_FORM"

export const setPasswordSent = flag => {
  return {
    type: SET_PASSWORD_SENT,
    payload: flag,
  }
}

export const setIsAddressNotRegistered = flag => {
  return {
    type: SET_IS_ADDRESS_NOT_REGISTERED,
    payload: flag,
  }
}

export const resetForm = () => {
  return {
    type: RESET_FORM,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case SET_PASSWORD_SENT:
      return {
        passwordSent: action.payload,
      }
    case SET_IS_ADDRESS_NOT_REGISTERED:
      return {
        isAddressNotRegistered: action.payload,
      }
    case RESET_FORM:
      return {
        passwordSent: false,
        isAddressNotRegistered: false,
      }
    default:
      break
  }

  return (
    state || {
      passwordSent: false,
      isAddressNotRegistered: false,
    }
  )
}
