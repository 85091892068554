import { ABOUTCONTENT_INTROPANEL_ID } from "../constants"

/**
 * Navigates to the timeline slide by scrolling by page height
 */
export function slideToTimeline() {
  window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
}

export function slideToAboutPanel() {
  const element = document.getElementById(ABOUTCONTENT_INTROPANEL_ID)
  element.scrollIntoView({ behavior: "smooth", block: "start" })
}
