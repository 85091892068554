import { isFSA } from "../../helpers/flux-standard-action"

export const ADD_CONSENT_REQUESTED = "consent/ADD_CONSENT_REQUESTED"
export const ADD_CONSENT_FINISHED = "consent/ADD_CONSENT_FINISHED"
export const REDIRECT_TO_CONSENT_DIALOG = "consent/REDIRECT_TO_CONSENT_DIALOG"
export const MORE_ABOUT_PLANS_AND_PRICING_CHOSEN =
  "consent/MORE_ABOUT_PLANS_AND_PRICING_CHOSEN"

export const CONSENTSTATUS_UNKOWN = "unkown"
export const CONSENTSTATUS_NONE = ""
export const CONSENTSTATUS_FUNCTIONAL_PERFORMANCE = "functional-performance"
export const CONSENTSTATUS_FULL = "functional-performance-targeting"
export const CONSENTSTATUS_BLOCKED = "cookies-blocked"

export const TXL_CONSENT_STATUS = "txl_consent_status"

export const requestAddConsent = consent => {
  return {
    type: ADD_CONSENT_REQUESTED,
    payload: consent,
  }
}

export const finishAddConsent = consentstatus => {
  return {
    type: ADD_CONSENT_FINISHED,
    payload: consentstatus,
  }
}

export const redirectToConsentDialog = (originalHref, language) => {
  return {
    type: REDIRECT_TO_CONSENT_DIALOG,
    payload: {
      originalHref,
      language,
    },
  }
}

export const chooseMoreAboutPlansAndPricing = () => {
  return {
    type: MORE_ABOUT_PLANS_AND_PRICING_CHOSEN,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case ADD_CONSENT_FINISHED: {
      return { ...state, status: action.payload, originalUrl: null }
    }
    case REDIRECT_TO_CONSENT_DIALOG: {
      return { ...state, originalUrl: action.payload.originalHref }
    }
    default:
      break
  }

  return (
    state || {
      status: CONSENTSTATUS_UNKOWN,
    }
  )
}
