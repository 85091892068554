import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const GET_DEFAULT_EMAIL_REQUESTED = "contacts/GET_DEFAULT_EMAIL_REQUESTED"
export const GET_DEFAULT_EMAIL_FINISHED = "contacts/GET_DEFAULT_EMAIL_FINISHED"
export const SET_DEFAULT_EMAIL_REQUESTED = "contacts/SET_DEFAULT_EMAIL_REQUESTED"
export const SET_DEFAULT_EMAIL_FINISHED = "contacts/SET_DEFAULT_EMAIL_FINISHED"
export const GET_CONTACTS_REQUESTED = "contacts/GET_CONTACTS_REQUESTED"
export const GET_CONTACTS_FINISHED = "contacts/GET_CONTACTS_FINISHED"

export const requestGetDefaultEmail = () => {
  return {
    type: GET_DEFAULT_EMAIL_REQUESTED,
  }
}

export const finishGetDefaultEmail = (email, error) => {
  return {
    type: GET_DEFAULT_EMAIL_FINISHED,
    error: !!error,
    payload: email || serializeError(error),
  }
}

export const requestSetDefaultEmail = email => {
  return {
    type: SET_DEFAULT_EMAIL_REQUESTED,
    payload: email,
  }
}

export const finishSetDefaultEmail = (email, error) => {
  return {
    type: SET_DEFAULT_EMAIL_FINISHED,
    error: !!error,
    payload: email || serializeError(error),
  }
}

export const requestGetContacts = () => {
  return {
    type: GET_CONTACTS_REQUESTED,
  }
}

export const finishGetContacts = (contacts, error) => {
  return {
    type: GET_CONTACTS_FINISHED,
    error: !!error,
    payload: contacts || serializeError(error),
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case GET_CONTACTS_FINISHED:
      if (!isError(action)) {
        return { ...state, recipientEmails: action.payload }
      }
      break
    case SET_DEFAULT_EMAIL_FINISHED:
    case GET_DEFAULT_EMAIL_FINISHED:
      if (!isError(action)) {
        return { ...state, defaultEmail: action.payload }
      }
      break
    default:
      break
  }

  return (
    state || { }
  )
}
