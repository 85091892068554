import React from "react"
import i18next from "i18next"
import { I18nextProvider, initReactI18next } from "react-i18next"
import LanguageService from "../services/languageservice"
import PagePropsProvider from "../components/PagePropsProvider"

export const AlternateLinksContext = React.createContext([])

export function wrapWithI18nProvider({ element, props }) {
  const i18n = i18next
    .createInstance({
      lng: props.pageContext.language,
      fallbackLng: "en",
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: props.pageContext.i18nResources,
      debug: process.env.NODE_ENV === "development",
    })
    .use(initReactI18next)
  i18n.init().then(() => LanguageService.setLanguage(i18n.language))

  return (
    <I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider
        value={props.pageContext && props.pageContext.alternateLinks}
      >
        <PagePropsProvider pageProps={props}>
          {element}
        </PagePropsProvider>
      </AlternateLinksContext.Provider>
    </I18nextProvider>
  )
}
