import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const ID_PASSWORD_CURRENT = "user_profile_password_current"
export const ID_PASSWORD_NEW = "user_profile_password_new"
export const ID_PASSWORD_CONFIRM = "user_profile_password_confirm"

export const PASSWORD_CHANGE_REQUESTED = "profile/PASSWORD_CHANGE_REQUESTED"
export const PASSWORD_CHANGE_FINISHED = "profile/PASSWORD_CHANGE_FINISHED"
export const SET_PASSWORD_ERRORS = "profile/SET_PASSWORD_ERRORS"

export const NAME_CHANGE_REQUESTED = "profile/NAME_CHANGE_REQUESTED"
export const NAME_CHANGE_FINISHED = "profile/NAME_CHANGE_FINISHED"

export const RESET_PROFILE_CHANGE_FLAGS = "profile/RESET_PROFILE_CHANGE_FLAGS"

export const DELETE_ACCOUNT_REQUESTED = "profile/DELETE_ACCOUNT_REQUESTED"

export const requestPasswordChange = (oldPassword, newPassword) => {
  return {
    type: PASSWORD_CHANGE_REQUESTED,
    payload: { oldPassword, newPassword },
  }
}

export const finishPasswordChange = error => {
  return {
    type: PASSWORD_CHANGE_FINISHED,
    error: !!error,
    payload: error,
  }
}

export const setPasswordErrors = errors => {
  return {
    type: SET_PASSWORD_ERRORS,
    payload: errors,
  }
}

export const requestNameChange = (firstName, lastName) => {
  return {
    type: NAME_CHANGE_REQUESTED,
    payload: { firstName, lastName },
  }
}

export const finishNameChange = error => {
  return {
    type: NAME_CHANGE_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const resetProfileChangeFlags = () => {
  return {
    type: RESET_PROFILE_CHANGE_FLAGS,
  }
}

export const requestDeleteAccount = (email, password, language) => {
  return {
    type: DELETE_ACCOUNT_REQUESTED,
    payload: { email, password, language },
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case SET_PASSWORD_ERRORS:
      return {
        ...state,
        passwordErrors: action.payload,
        hasErrors: Object.keys(action.payload).length > 0,
        hasPasswordChanged: false,
      }
    case PASSWORD_CHANGE_FINISHED:
      if (isError(action)) {
        const errors = {}
        errors[ID_PASSWORD_CURRENT] = action.payload
        return {
          ...state,
          passwordErrors: errors,
          hasErrors: true,
          hasPasswordChanged: false,
        }
      } else {
        return {
          ...state,
          hasPasswordChanged: true,
        }
      }
    case NAME_CHANGE_FINISHED:
      if (isError(action)) {
        return {
          ...state,
          hasNameChanged: false,
        }
      } else {
        return {
          ...state,
          hasNameChanged: true,
        }
      }
    case RESET_PROFILE_CHANGE_FLAGS:
      return {
        ...state,
        hasPasswordChanged: false,
        hasNameChanged: false,
      }
    default:
      break
  }

  return (
    state || {
      hasErrors: false,
      passwordErrors: {},
      hasPasswordChanged: false,
      hasNameChanged: false,
    }
  )
}
