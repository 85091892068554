const SUBDOMAIN_PATTERN =
  /([A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?)\.transferxl\.com/
const DOWNLOAD_PATTERN = /\/([0-9A-F]{2}[a-zA-Z0-9]+)\/?$/

const config = {
  facebook: {
    dev: "1567818523448394",
    prod: "1463057357257845",
  },
  google: {
    dev: "53617634084-idvnrflffmg54348ssoa16p149ovvdp3.apps.googleusercontent.com",
    prod: "306165234901-cbncsmojtpcamjflk35jus7tfbum2666.apps.googleusercontent.com",
  },
  googleMerchantCenter: {
    merchantId: 136377277,
  },
  grecaptchaWidgetId: "6LeNlEgaAAAAADskhBogcjBYR3MGLhJ7KZXbSvFR",
  applicationInsights: "d58f41eb-1a9c-4f75-b654-2b3103170ab1",
  enableAnalyticsHostnames: ["www.transferxl.com", "*.netlify.app"],
  simulateLoginRedirectHostnames: [
    "localhost",
    "local.transferxl.com",
    "*.netlify.app",
  ],
  pageTranslationNamespaces: {
    default: ["generic"],
    index: ["generic", "about", "usp"],
    settings: ["generic", "about", "usp"],
    about: ["generic", "about-modal"],
    terms: ["generic", "terms-and-conditions"],
    privacy: ["generic", "privacy-policy"],
    contact: ["generic", "contact"],
  },
  pageSupportsWildcardRoutes: {
    settings: true,
    download: true,
    details: true,
    manage: true,
  },
  pathValidWithoutConsent: [
    /\/consent\/*$/,
    /\/plans\/*$/,
    /\/login\/*$/,
    /\/signup\/*$/,
    /\/contact\/*$/,
    /\/legal\/terms\/*$/,
    /\/legal\/privacy\/*$/,
    /\/profile\/step[1-4]\/*$/,
    /\/activate\/*$/,
    /\/thankyou\/*$/,
    /\/languages\/*$/,
  ],
  timeline: {
    limit: 20,
  },
  uploadservice: {
    protocol: "https",
  },
  backend: {
    overwrite: false,
  },
  media: {
    prefix: "https://cdn.transferxl.com",
  },
  index: {
    hosts: ["localhost", "www.transferxl.com", "*.netlify.app"],
    paths: [
      "",
      "about",
      "contact",
      "plans",
      "legal/terms",
      "legal/privacy",
      "languages",
    ],
  },
  subscription: {
    plans: ["pro", "pro-yearly", "ent", "ent-yearly"],
  },
  domain: {
    subdomainPattern: SUBDOMAIN_PATTERN,
    downloadPattern: DOWNLOAD_PATTERN,
  },
  gtm: {
    id: "GTM-MG23D22",
    getParams: siteUrl => {
      switch (siteUrl) {
        case "https://beta.transferxl.com":
          return { auth: "s9acIwUUovPh1t4VDkQoXw", preview: "env-32" }
        default:
          break
      }
      return { auth: "VWCrl_bgUNJAy6Hw_dCfFA", preview: "env-2" }
    },
  },
  whitelistParameters: [
    "gclid",
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_content",
    "utm_campaign",
    "_ga",
    "_gac",
    "_gl",
  ],
  logging: {
    dev: {
      log: true,
      debug: true,
      info: true,
      warn: true,
      error: true,
    },
    prod: {
      log: false,
      debug: false,
      info: false,
      warn: true,
      error: true,
    },
  },
}

const returnConfig = {}

if (process.env.NODE_ENV === "production") {
  for (let key of Object.keys(config)) {
    returnConfig[key] = config[key].prod ? config[key].prod : config[key]
  }
} else {
  for (let key of Object.keys(config)) {
    returnConfig[key] = config[key].dev ? config[key].dev : config[key]
  }
}

module.exports = returnConfig
