import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const DOWNLOAD_REQUESTED = "download/DOWNLOAD_REQUESTED"
export const DOWNLOAD_REQUEST_FINISHED = "download/DOWNLOAD_REQUEST_FINISHED"
export const RESET_LAST_DOWNLOAD = "download/RESET_LAST_DOWNLOAD"

export const requestDownload = transfer => {
  return {
    type: DOWNLOAD_REQUESTED,
    payload: transfer,
  }
}

export const finishDownloadRequest = (shortUrl, error) => {
  return {
    type: DOWNLOAD_REQUEST_FINISHED,
    error: !!error,
    payload: {
      shortUrl,
      error: serializeError(error),
    },
  }
}

export const resetLastDownload = () => {
  return {
    type: RESET_LAST_DOWNLOAD,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case DOWNLOAD_REQUEST_FINISHED: {
      if (!isError(action)) {
        return { ...state, lastDownloadRequest: action.payload.shortUrl }
      }
      break
    }
    case RESET_LAST_DOWNLOAD:
      return { ...state, lastDownloadRequest: null }
    default:
      break
  }

  return state || {}
}
