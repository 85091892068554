import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const ADD_FILES = "upload/ADD_FILES"
export const UPDATE_FILE_STATS = "upload/UPDATE_FILE_STATS"
export const ADD_RECIPIENT = "upload/ADD_RECIPIENT"
export const SET_MESSAGE = "upload/SET_MESSAGE"
export const SET_PASSPHRASE = "upload/SET_PASSPHRASE"
export const CHECK_TRANSFER_REQUESTED = "upload/CHECK_TRANSFER_REQUESTED"
export const CHECK_TRANSFER_FINISHED = "upload/CHECK_TRANSFER_FINISHED"
export const RESET_CHECK_TRANSFER_RESULT = "upload/RESET_CHECK_TRANSFER_RESULT"
export const SEND_TRANSFER = "upload/SEND_TRANSFER"
export const SEND_TRANSFER_FINISHED = "upload/SEND_TRANSFER_FINISHED"
export const SET_SHAREMETHOD = "upload/SET_SHAREMETHOD"
export const FILE_UPLOAD_START_REQUESTED = "upload/FILE_UPLOAD_START_REQUESTED"
export const FILE_UPLOAD_STARTED = "upload/FILE_UPLOAD_STARTED"
export const FILE_UPLOAD_PROGRESS = "upload/FILE_UPLOAD_PROGRESS"
export const START_NEW_UPLOAD = "upload/START_NEW_UPLOAD"
export const SET_EMAIL = "upload/SET_EMAIL"
export const CANCEL_UPLOAD_REQUESTED = "upload/CANCEL_UPLOAD_REQUESTED"
export const CANCEL_UPLOAD_FINISHED = "upload/CANCEL_UPLOAD_FINISHED"
export const DUPLICATE_FILES_CHECK_COMPLETED =
  "upload/DUPLICATE_FILES_CHECK_COMPLETED"
export const CANCEL_ADD_FILES = "upload/CANCEL_ADD_FILES"
export const CHANGE_DUPLICATE_FILE_ACTION =
  "upload/CHANGE_DUPLICATE_FILE_ACTION"

export const addFiles = (uuid, files, duplicateFiles) => {
  return {
    type: ADD_FILES,
    payload: { uuid, files, duplicateFiles },
  }
}

export const updateFileStats = fileStats => {
  return {
    type: UPDATE_FILE_STATS,
    payload: fileStats,
  }
}

export const addRecipient = email => {
  return {
    type: ADD_RECIPIENT,
    payload: {
      email,
    },
  }
}

export const setMessage = msg => {
  return {
    type: SET_MESSAGE,
    payload: msg,
  }
}

export const setPassphrase = passphrase => {
  return {
    type: SET_PASSPHRASE,
    payload: passphrase,
  }
}

export const requestCheckTransfer = options => {
  return {
    type: CHECK_TRANSFER_REQUESTED,
    payload: options,
  }
}

export const finishCheckTransfer = checkResponse => {
  return {
    type: CHECK_TRANSFER_FINISHED,
    payload: checkResponse,
  }
}

export const resetCheckTransferResult = resultKey => {
  return {
    type: RESET_CHECK_TRANSFER_RESULT,
    payload: resultKey,
  }
}

export const sendTransfer = options => {
  return {
    type: SEND_TRANSFER,
    payload: options,
  }
}

export const finishTransfer = (uuid, response) => {
  const { result, error } = response
  return {
    type: SEND_TRANSFER_FINISHED,
    error: !!error || !response.ok,
    payload: {
      uuid,
      result,
      error: serializeError(error),
    },
  }
}

export const setShareMethod = shareMethod => {
  return {
    type: SET_SHAREMETHOD,
    payload: shareMethod,
  }
}

export const requestStartFileUpload = options => {
  return {
    type: FILE_UPLOAD_START_REQUESTED,
    payload: options,
  }
}

export const startedFileUpload = fileStats => {
  return {
    type: FILE_UPLOAD_STARTED,
    payload: fileStats,
  }
}

export const progressFileUpload = (uuid, progress) => {
  return {
    type: FILE_UPLOAD_PROGRESS,
    payload: { uuid, progress },
  }
}

export const startNewUpload = () => {
  return {
    type: START_NEW_UPLOAD,
  }
}

export const setEmail = email => {
  return {
    type: SET_EMAIL,
    payload: email,
  }
}

export const requestCancelUpload = uuid => {
  return {
    type: CANCEL_UPLOAD_REQUESTED,
    payload: uuid,
  }
}

export const finishCancelUpload = (uuid, error, isQueued) => {
  return {
    type: CANCEL_UPLOAD_FINISHED,
    error: !!error,
    payload: {
      uuid,
      error: serializeError(error),
      isQueued,
    },
  }
}

export const completeDuplicateFilesCheck = duplicateFiles => {
  return {
    type: DUPLICATE_FILES_CHECK_COMPLETED,
    payload: { duplicateFiles },
  }
}

export const cancelAddFiles = () => {
  return {
    type: CANCEL_ADD_FILES,
  }
}

export const changeDuplicateFileAction = (fileIndices, action) => {
  return {
    type: CHANGE_DUPLICATE_FILE_ACTION,
    payload: { fileIndices, action },
  }
}

const initialState = {
  newFiles: [],
  fileStats: { count: 0, size: 0 },
  passphrase: "",
  shareMethod: "link",
  inProgress: false,
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case DUPLICATE_FILES_CHECK_COMPLETED:
      return {
        ...state,
        duplicateFiles: action.payload.duplicateFiles,
      }
    case CHANGE_DUPLICATE_FILE_ACTION: {
      const newDuplicateFiles = []
      const changedIndices = action.payload.fileIndices
      const newAction = action.payload.action

      for (let i = 0; i < state.duplicateFiles.length; i++) {
        if (changedIndices.indexOf(i) >= 0) {
          newDuplicateFiles.push({
            ...state.duplicateFiles[i],
            action: newAction,
          })
        } else {
          newDuplicateFiles.push(state.duplicateFiles[i])
        }
      }

      return {
        ...state,
        duplicateFiles: newDuplicateFiles,
      }
    }
    case CANCEL_ADD_FILES:
      return {
        ...state,
        duplicateFiles: null,
      }
    case ADD_FILES:
      return {
        ...state,
        newFiles: action.payload.files,
        duplicateFiles: action.payload.duplicateFiles,
      }
    case UPDATE_FILE_STATS:
      const { fileCount, fileSize, isSingleZipFile } = action.payload
      return {
        ...state,
        fileStats: { count: fileCount, size: fileSize },
        duplicateFiles: null,
        checkresult: { isSingleZipFile },
      }
    case ADD_RECIPIENT:
      return {
        ...state,
        // TODO there must be the possibility for multiple recipients
        recipients: [action.payload.email],
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    case SET_PASSPHRASE:
      return {
        ...state,
        passphrase: action.payload,
      }
    case CHECK_TRANSFER_FINISHED:
      return {
        ...state,
        checkresult: action.payload.result,
      }
    case RESET_CHECK_TRANSFER_RESULT:
      return {
        ...state,
        checkresult: {
          ...state.checkresult,
          [action.payload]: false,
        },
      }
    case SEND_TRANSFER:
      return {
        ...state,
        error: null,
      }
    case SEND_TRANSFER_FINISHED:
      return { ...state, inProgress: false }
    case SET_SHAREMETHOD:
      return { ...state, shareMethod: action.payload }
    case FILE_UPLOAD_STARTED:
      return { ...state, inProgress: true }
    case START_NEW_UPLOAD:
      return {
        ...initialState,
        inProgress: state.inProgress,
      }
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case CANCEL_UPLOAD_FINISHED:
      if (!isError(action)) {
        const { isQueued } = action.payload
        if (!isQueued) {
          return { ...state, inProgress: false }
        }
      }
      break
    default:
      break
  }
  return state || initialState
}
