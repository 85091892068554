import { isFSA } from "../../helpers/flux-standard-action"
import { slideToAboutPanel } from "../../helpers/scrolling"

export const CLOSE_USP_WIDGET = "usp/CLOSE_USP_WIDGET"

export const closeUspWidget = () => {
  return {
    type: CLOSE_USP_WIDGET,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case CLOSE_USP_WIDGET:
      return {
        ...state,
        isClosedByUser: true,
      }
    default:
      break
  }

  return (
    state || {
      isClosedByUser: false,
      items: [
        {
          title: "##_USP.Speed.TransferFiles",
          uspText: "##_USP.Speed.Faster",
          extraText: "##_USP.Speed.PatentedTechnology",
          buttonText: "##_USP.Speed.SeeBenchmarks",
        },
        {
          title: "##_USP.Pro.UpgradeToPro",
          uspText: "##_USP.Pro.BigTransfers",
          extraText: "##_USP.Pro.Customization",
          buttonText: "##_USP.Pro.BecomePro",
          buttonLinkTarget: "/plans",
        },
        {
          title: "##_USP.Size.TransferFiles",
          uspText: "##_USP.Size.UpperLimit",
          extraText: "##_USP.Size.FreeLimit",
          buttonText: "##_USP.Size.CompareUs",
          buttonLinkTarget: () => slideToAboutPanel(),
        },
        {
          title: "##_USP.Encryption.TransferFiles",
          uspText: "##_USP.Encryption.Secure",
          extraText: "##_USP.Encryption.WithEndToEndEncryption",
          buttonText: "##_USP.Encryption.LearnMore",
        },
      ],
    }
  )
}
