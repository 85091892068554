// Helper function to always get some meaningful serialized value from a polymorphic error
export function serializeError(error) {
  if (!error) {
    return null
  }
  if (typeof error === "string") {
    return error
  }

  const errorString = JSON.stringify(error)
  if (errorString !== "{}") {
    return errorString
  }

  if (error.message) {
    return error.message
  }

  return typeof error
}
