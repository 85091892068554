import { filter, first } from "lodash"
import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const GET_ACCOUNTINFO_REQUESTED = "recurly/GET_ACCOUNTINFO_REQUESTED"
export const GET_ACCOUNTINFO_FINISHED = "recurly/GET_ACCOUNTINFO_FINISHED"
export const RESET_RECURLY_ERRORS = "recurly/RESET_RECURLY_ERRORS"
export const CANCEL_SUBSCRIPTION_REQUESTED =
  "recurly/CANCEL_SUBSCRIPTION_REQUESTED"
export const CANCEL_SUBSCRIPTION_FINISHED =
  "recurly/CANCEL_SUBSCRIPTION_FINISHED"
export const REACTIVATE_SUBSCRIPTION_REQUESTED =
  "recurly/REACTIVATE_SUBSCRIPTION_REQUESTED"
export const REACTIVATE_SUBSCRIPTION_FINISHED =
  "recurly/REACTIVATE_SUBSCRIPTION_FINISHED"

export const requestGetAccountInfo = () => {
  return {
    type: GET_ACCOUNTINFO_REQUESTED,
  }
}

export const finishGetAccountInfo = (accountInfo, error) => {
  return {
    type: GET_ACCOUNTINFO_FINISHED,
    error: !!error,
    payload: serializeError(error) || accountInfo,
  }
}

export const resetRecurlyErrors = () => {
  return {
    type: RESET_RECURLY_ERRORS,
  }
}

export const requestCancelSubscription = subscription => {
  return {
    type: CANCEL_SUBSCRIPTION_REQUESTED,
    payload: subscription,
  }
}

export const finishCancelSubscription = (subscription, error) => {
  return {
    type: CANCEL_SUBSCRIPTION_FINISHED,
    error: !!error,
    payload: serializeError(error) || subscription,
  }
}

export const requestReactivateSubscription = subscription => {
  return {
    type: REACTIVATE_SUBSCRIPTION_REQUESTED,
    payload: subscription,
  }
}

export const finishReactivateSubscription = (subscription, error) => {
  return {
    type: REACTIVATE_SUBSCRIPTION_FINISHED,
    error: !!error,
    payload: serializeError(error) || subscription,
  }
}

function getSubscription(subscriptions, id) {
  const subscription = first(filter(subscriptions, e => e.id === id))

  const index = subscriptions.indexOf(subscription)

  return [subscription, index]
}

function getStateWithUpdatedSubscription(state, action) {
  const subscriptions = state.accountInfo?.subscriptions
  if (!isError(action) && state.accountInfo?.subscriptions) {
    const newSubscription = action.payload
    const [, index] = getSubscription(subscriptions, newSubscription.id)
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        subscriptions: [
          ...subscriptions.slice(0, index),
          newSubscription,
          ...subscriptions.slice(index + 1),
        ],
      },
    }
  }
  return state
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case GET_ACCOUNTINFO_FINISHED:
      if (isError(action)) {
        return { ...state, hasGetAccountInfoFailed: true }
      } else {
        return {
          ...state,
          accountInfo: action.payload,
          hasGetAccountInfoFailed: false,
        }
      }
    case RESET_RECURLY_ERRORS:
      return { ...state, hasGetAccountInfoFailed: false }
    case CANCEL_SUBSCRIPTION_FINISHED:
      return getStateWithUpdatedSubscription(state, action)
    case REACTIVATE_SUBSCRIPTION_FINISHED:
      return getStateWithUpdatedSubscription(state, action)
    default:
      break
  }

  return state || {}
}
