import { filter, first } from "lodash"

export function getEvents(events, predicate) {
  return filter(events, predicate)
}

export function getFirstEvent(events, predicate) {
  const event = first(filter(events, predicate))

  const index = events.indexOf(event)

  return [event, index]
}

export function getEvent(events, id) {
  return getFirstEvent(events, e => e.id === id || e.uuid === id)
}
