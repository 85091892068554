/**
 * The code is taken from https://github.com/redux-utilities/flux-standard-action/blob/master/src/index.js
 * Using the npm package "flux-standard-action" somehow resulted in the entirety of lodash being included
 * and shipped with the build.
 */
import { isPlainObject, isString } from "lodash"

export function isFSA(action) {
  return (
    isPlainObject(action) &&
    isString(action.type) &&
    Object.keys(action).every(isValidKey)
  )
}

export function isError(action) {
  return isFSA(action) && action.error === true
}

export function isValidKey(key) {
  return ["type", "payload", "error", "meta"].indexOf(key) > -1
}
