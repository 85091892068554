exports.components = {
  "component---src-pages-oauth-finish-login-html-jsx": () => import("./../../../src/pages/oauthFinishLogin.html.jsx" /* webpackChunkName: "component---src-pages-oauth-finish-login-html-jsx" */),
  "component---src-pages-oauthfinishlogin-jsx": () => import("./../../../src/pages/oauthfinishlogin.jsx" /* webpackChunkName: "component---src-pages-oauthfinishlogin-jsx" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-activate-jsx": () => import("./../../../src/templates/activate.jsx" /* webpackChunkName: "component---src-templates-activate-jsx" */),
  "component---src-templates-consent-jsx": () => import("./../../../src/templates/consent.jsx" /* webpackChunkName: "component---src-templates-consent-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-customisation-jsx": () => import("./../../../src/templates/customisation.jsx" /* webpackChunkName: "component---src-templates-customisation-jsx" */),
  "component---src-templates-details-jsx": () => import("./../../../src/templates/details.jsx" /* webpackChunkName: "component---src-templates-details-jsx" */),
  "component---src-templates-download-jsx": () => import("./../../../src/templates/download.jsx" /* webpackChunkName: "component---src-templates-download-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-languages-jsx": () => import("./../../../src/templates/languages.jsx" /* webpackChunkName: "component---src-templates-languages-jsx" */),
  "component---src-templates-legal-privacy-jsx": () => import("./../../../src/templates/legal/privacy.jsx" /* webpackChunkName: "component---src-templates-legal-privacy-jsx" */),
  "component---src-templates-legal-terms-jsx": () => import("./../../../src/templates/legal/terms.jsx" /* webpackChunkName: "component---src-templates-legal-terms-jsx" */),
  "component---src-templates-login-jsx": () => import("./../../../src/templates/login.jsx" /* webpackChunkName: "component---src-templates-login-jsx" */),
  "component---src-templates-manage-jsx": () => import("./../../../src/templates/manage.jsx" /* webpackChunkName: "component---src-templates-manage-jsx" */),
  "component---src-templates-plans-jsx": () => import("./../../../src/templates/plans.jsx" /* webpackChunkName: "component---src-templates-plans-jsx" */),
  "component---src-templates-profile-index-jsx": () => import("./../../../src/templates/profile/index.jsx" /* webpackChunkName: "component---src-templates-profile-index-jsx" */),
  "component---src-templates-profile-step-1-jsx": () => import("./../../../src/templates/profile/step1.jsx" /* webpackChunkName: "component---src-templates-profile-step-1-jsx" */),
  "component---src-templates-profile-step-2-jsx": () => import("./../../../src/templates/profile/step2.jsx" /* webpackChunkName: "component---src-templates-profile-step-2-jsx" */),
  "component---src-templates-profile-step-3-jsx": () => import("./../../../src/templates/profile/step3.jsx" /* webpackChunkName: "component---src-templates-profile-step-3-jsx" */),
  "component---src-templates-profile-step-4-jsx": () => import("./../../../src/templates/profile/step4.jsx" /* webpackChunkName: "component---src-templates-profile-step-4-jsx" */),
  "component---src-templates-reset-password-jsx": () => import("./../../../src/templates/reset-password.jsx" /* webpackChunkName: "component---src-templates-reset-password-jsx" */),
  "component---src-templates-settings-jsx": () => import("./../../../src/templates/settings.jsx" /* webpackChunkName: "component---src-templates-settings-jsx" */),
  "component---src-templates-signup-jsx": () => import("./../../../src/templates/signup.jsx" /* webpackChunkName: "component---src-templates-signup-jsx" */),
  "component---src-templates-subscription-jsx": () => import("./../../../src/templates/subscription.jsx" /* webpackChunkName: "component---src-templates-subscription-jsx" */),
  "component---src-templates-thankyou-jsx": () => import("./../../../src/templates/thankyou.jsx" /* webpackChunkName: "component---src-templates-thankyou-jsx" */),
  "component---src-templates-welcome-jsx": () => import("./../../../src/templates/welcome.jsx" /* webpackChunkName: "component---src-templates-welcome-jsx" */)
}

