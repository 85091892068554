import { isFSA } from "../../helpers/flux-standard-action"

export const SET_CREATED_IMAGES = "bgfader/SET_CREATED_IMAGES"

export const setCreatedImages = createdImages => {
  return {
    type: SET_CREATED_IMAGES,
    payload: createdImages,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case SET_CREATED_IMAGES:
      return { createdImages: action.payload }
    default:
      break
  }

  return state || {}
}
