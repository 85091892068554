import { combineReducers } from "redux"
import { reducer as subscription } from "../modules/subscription"
import { reducer as login } from "../modules/login"
import { reducer as signup } from "../modules/signup"
import { reducer as upload } from "../modules/upload"
import { reducer as region } from "../modules/region"
import { reducer as timeline } from "../modules/timeline"
import { reducer as download } from "../modules/download"
import { reducer as transfer } from "../modules/transfer"
import { reducer as profile } from "../modules/profile"
import { reducer as domaincustomisation } from "../modules/domaincustomisation"
import { reducer as bgcustomisation } from "../modules/bgcustomisation"
import { reducer as bgfader } from "../modules/bgfader"
import { reducer as resetpassword } from "../modules/resetpassword"
import { reducer as usp } from "../modules/usp"
import { reducer as recurly } from "../modules/recurly"
import { reducer as help } from "../modules/help"
import { reducer as contacts } from "../modules/contacts"
import { reducer as ready } from "../modules/ready"
import { reducer as consent } from "../modules/consent"
import { reducer as filedrop } from "../modules/filedrop"

const createRootReducer = () =>
  combineReducers({
    login,
    signup,
    subscription,
    upload,
    region,
    timeline,
    download,
    transfer,
    profile,
    domaincustomisation,
    bgcustomisation,
    bgfader,
    resetpassword,
    usp,
    recurly,
    help,
    contacts,
    ready,
    consent,
    filedrop,
  })

export default createRootReducer
