import { v1 as timestampUuid } from "uuid"
import { isError, isFSA } from "../../helpers/flux-standard-action"
import TransferData from "../../models/TransferData"
import { LOGOUT_FINISHED } from "./login"
import { DELETE_UPLOAD_FINISHED } from "./transfer"
import {
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_STARTED,
  SEND_TRANSFER,
  SEND_TRANSFER_FINISHED,
  START_NEW_UPLOAD,
  UPDATE_FILE_STATS,
  SET_PASSPHRASE,
  CANCEL_UPLOAD_FINISHED,
} from "./upload"
import {
  getEvent,
  getFirstEvent,
  getEvents,
} from "../../helpers/timelinehelpers"

export const LOAD_TIMELINE_REQUESTED = "timeline/LOAD_TIMELINE_REQUESTED"
export const LOAD_TIMELINE_FINISHED = "timeline/LOAD_TIMELINE_FINISHED"
export const TRANSFORM_UPLOAD = "timeline/TRANSFORM_UPLOAD"

export const ANTICIPATED_UPLOAD_ID = "timeline-event-anticipated-upload"
export const CURRENT_UPLOAD_ID = "timeline-event-upload"

export const requestLoadTimeline = nextToken => {
  return {
    type: LOAD_TIMELINE_REQUESTED,
    payload: {
      nextToken,
    },
  }
}

export const finishLoadTimeline = (response, error) => {
  return {
    type: LOAD_TIMELINE_FINISHED,
    error: !!error,
    payload: error || response,
  }
}

export const transformUpload = eventId => {
  return {
    type: TRANSFORM_UPLOAD,
    payload: eventId,
  }
}

function getCurrentUploadEvent(events) {
  return getFirstEvent(events, e => e.type === "upload-confirmed")
}

function createNewTransfer() {
  return {
    id: CURRENT_UPLOAD_ID,
    uuid: timestampUuid(),
    type: "upload-confirmed",
    transferData: new TransferData({
      isNewTransfer: true,
    }),
  }
}

function replaceEvent(events, eventId, changedPropsFunc) {
  const [event, eventIndex] = getEvent(events, eventId)

  const changedProps = changedPropsFunc(event)

  const newEvent = {
    ...event,
    ...changedProps,
  }

  return [
    ...events.slice(0, eventIndex),
    newEvent,
    ...events.slice(eventIndex + 1),
  ]
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case TRANSFORM_UPLOAD: {
      const events = replaceEvent(state.events, action.payload, e => ({
        transferData: e.transferData.copy({
          isFinished: false,
        }),
      }))

      return {
        ...state,
        events,
      }
    }
    case SET_PASSPHRASE: {
      const isEncrypted = !!action.payload
      const [uploadEvent, eventIndex] = getCurrentUploadEvent(state.events)
      const newEvent = {
        ...uploadEvent,
        transferData: uploadEvent.transferData.copy({
          isEncrypted,
        }),
      }
      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          newEvent,
          ...state.events.slice(eventIndex + 1),
        ],
      }
    }
    case UPDATE_FILE_STATS: {
      const { fileCount, fileSize } = action.payload
      const [uploadEvent, eventIndex] = getCurrentUploadEvent(state.events)
      const newEvent = {
        ...uploadEvent,
        transferData: uploadEvent.transferData.copy({ fileCount, fileSize }),
      }
      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          newEvent,
          ...state.events.slice(eventIndex + 1),
        ],
      }
    }
    case SEND_TRANSFER: {
      const { uuid, shareMethod, email } = action.payload
      const [uploadEvent, eventIndex] = getEvent(state.events, uuid)
      const anticipatedUpload = {
        id: ANTICIPATED_UPLOAD_ID,
        type: "upload-anticipated",
      }
      const confirmedUpload = {
        ...uploadEvent,
        transferData: uploadEvent.transferData.copy({
          isNewTransfer: false,
          isQueued: true,
          shareMethod,
          recipients: email?.recipients,
        }),
      }
      return {
        ...state,
        events: [
          anticipatedUpload,
          ...state.events.slice(0, eventIndex),
          confirmedUpload,
          ...state.events.slice(eventIndex + 1),
        ],
      }
    }
    case FILE_UPLOAD_STARTED: {
      const { uuid, fileCount, fileSize } = action.payload
      const events = replaceEvent(state.events, uuid, e => ({
        transferData: e.transferData.copy({
          isQueued: false,
          isUploading: true,
          fileCount,
          fileSize,
        }),
      }))
      return {
        ...state,
        events,
      }
    }
    case FILE_UPLOAD_PROGRESS: {
      const { uuid, progress } = action.payload
      const [uploadEvent, eventIndex] = getEvent(state.events, uuid)

      // It happens occasionally that uploadedBytes > totalUploadSize
      const progressValue = Math.min(
        progress.uploadedBytes / progress.totalUploadSize,
        1
      )

      const inProgressEvent = {
        ...uploadEvent,
        transferData: uploadEvent.transferData.copy({
          shareUrl: progress.shareUrl,
          isUploading: true,
          progress: {
            value: progressValue,
            secondsRemaining: progress.secondsLeft,
          },
        }),
      }
      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          inProgressEvent,
          ...state.events.slice(eventIndex + 1),
        ],
      }
    }
    case SEND_TRANSFER_FINISHED: {
      const { uuid } = action.payload
      const [uploadEvent, eventIndex] = getEvent(state.events, uuid)

      let newEvent = { ...uploadEvent }
      if (isError(action)) {
        newEvent.transferData = uploadEvent.transferData.copy({
          isUploading: false,
          isCancelled: true,
        })
      } else {
        const { result } = action.payload

        newEvent.id = result.id
        newEvent.transferData = uploadEvent.transferData.copy({
          isUploading: false,
          isFinished: true,
          progress: { value: 1 },
        })
      }

      return {
        ...state,
        events: [
          ...state.events.slice(0, eventIndex),
          newEvent,
          ...state.events.slice(eventIndex + 1),
        ],
      }
    }
    case START_NEW_UPLOAD: {
      const [currentUpload, eventIndex] = getCurrentUploadEvent(state.events)
      // If the old transfer is not confirmed, it is simply deleted (canceled)
      // If there is no current upload, there is the anticipated upload we have to get rid of
      if (!currentUpload || currentUpload.transferData.isNewTransfer) {
        return {
          ...state,
          events: [createNewTransfer(), ...state.events.slice(1)],
        }
      } else {
        const newEvent = {
          ...currentUpload,
          type: "upload",
        }
        return {
          ...state,
          hasHistory: true,
          events: [
            createNewTransfer(),
            newEvent,
            ...state.events.slice(eventIndex + 1),
          ],
        }
      }
    }
    case CANCEL_UPLOAD_FINISHED: {
      if (!isError(action)) {
        const { uuid } = action.payload
        const [uploadEvent, eventIndex] = getEvent(state.events, uuid)

        // If the canceled upload is the latest confirmed event, we delete it and create a new empty upload
        if (uploadEvent.type === "upload-confirmed") {
          return {
            ...state,
            events: [
              createNewTransfer(),
              ...state.events.slice(eventIndex + 1),
            ],
          }
        } else {
          // otherwise we just delete the event
          return {
            ...state,
            events: [
              ...state.events.slice(0, eventIndex),
              ...state.events.slice(eventIndex + 1),
            ],
          }
        }
      }
      break
    }
    case LOAD_TIMELINE_REQUESTED: {
      return {
        ...state,
        isFetchingTransfers: true,
      }
    }
    case LOAD_TIMELINE_FINISHED: {
      if (isError(action)) {
        return {
          ...state,
          isFetchingTransfers: false,
          // prevent loop in error case
          isLoaded: true,
        }
      }

      const timelineEvents = action.payload.transfers.map(transfer => {
        const eventData = {
          id: transfer.id,
          type: transfer.transfer,
          transferData: transfer,
        }
        // easier to handle downloads and uploads in the same way
        if (!eventData.transferData.details) {
          eventData.transferData.details = {
            isDownloaded: transfer.status.isDownloaded,
            isExpired: transfer.status.isExpired,
            createdAt: transfer.createdAt,
            availableUntil: transfer.availableUntil,
            shareMethod: transfer.options.shareMethod,
            fromEmail: transfer.options.fromEmail,
          }
        }
        // This is for compatibility with existing components (could be dropped if components are adjusted)
        eventData.transferData.details.recipients =
          transfer.options.email?.recipients || []
        eventData.transferData.details.message = transfer.options.message

        return eventData
      })

      timelineEvents.sort((a, b) => {
        const createdA = a.transferData.details.createdAt
        const createdB = b.transferData.details.createdAt
        if (createdA > createdB) return -1
        if (createdA < createdB) return 1
        return 0
      })

      return {
        ...state,
        isFetchingTransfers: false,
        nextToken: action.payload.nextToken || null,
        isLoaded: true,
        hasHistory: timelineEvents.length > 0,
        events: [...state.events, ...timelineEvents],
      }
    }
    case LOGOUT_FINISHED: {
      // Uploads that are in progress or queued survive a logout.
      // This mirrors the behaviour of the current live app
      const inProgressEvents = getEvents(
        state.events,
        e =>
          !e.transferData ||
          e.transferData.isNewTransfer ||
          e.transferData.isUploading?.call(e.transferData) ||
          e.transferData.isQueued?.call(e.transferData)
      )

      return {
        ...state,
        isLoaded: false,
        hasHistory: false,
        events:
          inProgressEvents.length > 0
            ? inProgressEvents
            : [createNewTransfer()],
      }
    }
    case DELETE_UPLOAD_FINISHED: {
      if (!isError(action)) {
        const id = action.payload.shortUrl

        const [event, eventIndex] = getEvent(state.events, id)

        // Remove the deleted upload from the timeline
        if (event) {
          return {
            ...state,
            events: [
              ...state.events.slice(0, eventIndex),
              ...state.events.slice(eventIndex + 1),
            ],
          }
        }
      }
      break
    }
    default:
      break
  }

  return (
    state || {
      isLoaded: false,
      hasHistory: false,
      isFetchingTransfers: false,
      events: [createNewTransfer()],
    }
  )
}
