import { applyMiddleware, createStore, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { window } from "browser-monads"
import queryString from "query-string"
import { isSSR } from "../helpers/ssrcheck"
import { readWelcomeTourStep } from "./modules/help"
import { requestTokenLogin, requestTokenReset } from "./modules/login"
import { initializeSagas } from "./modules/ready"

import createRootReducer from "./reducers/root"

/**
 * This must be called AFTER the sagas are initialized.
 * Otherwise the dispatch will have no effect.
 * @param {function} dispatch
 */
function handleParameters(dispatch) {
  const location = window.location

  if (location) {
    const { token, resetToken } = queryString.parse(location.search)
    const pathname = location.pathname

    if (resetToken) {
      dispatch(requestTokenReset(pathname))
    } else {
      dispatch(requestTokenLogin(token, pathname))
    }
  }
}

async function initSagas(store, sagaMiddleware) {
  // Only run sagas on the client side render
  if (!isSSR()) {
    const { default: getSagas } = await import("./sagas/root")

    let sagaTask = sagaMiddleware.run(function* () {
      yield getSagas()
    })

    if (module.hot) {
      module.hot.accept(["./reducers/root"], () => {
        const createUpdatedRootReducer = require("./reducers/root").default
        store.replaceReducer(createUpdatedRootReducer())
      })
      module.hot.accept("./sagas/root", () => {
        import("./sagas/root").then(({ default: getUpdatedSagas }) => {
          sagaTask.cancel()
          sagaTask.toPromise().then(() => {
            sagaTask = sagaMiddleware.run(function* () {
              yield getUpdatedSagas()
            })
          })
        })
      })
    }

    store.dispatch(initializeSagas())
  }

  store.dispatch(readWelcomeTourStep())
  handleParameters(store.dispatch)
}

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware()

  let composeFn = arg => compose(arg)

  if (process.env.NODE_ENV === "development") {
    const { composeWithDevTools } = require("redux-devtools-extension")
    composeFn = arg => composeWithDevTools(arg)
  }

  const store = createStore(
    createRootReducer(),
    preloadedState,
    composeFn(applyMiddleware(sagaMiddleware))
  )

  if (window.requestIdleCallback) {
    window.requestIdleCallback(() => initSagas(store, sagaMiddleware))
  } else {
    initSagas(store, sagaMiddleware)
  }

  return store
}
