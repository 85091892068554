export default class TransferData {
  #_options

  constructor(options) {
    this.shareUrl = options.shareUrl
    this.isNewTransfer = options.isNewTransfer
    this.fileCount = options.fileCount || 0
    this.fileSize = options.fileSize || 0
    this._isUploading = options.isUploading
    this.progress = options.progress || { value: 0 }
    this._isQueued = options.isQueued
    this.isCancelled = options.isCancelled
    this.isFinished = options.isFinished
    this.isEncrypted = options.isEncrypted
    this.details = {
      isDownloaded: options.isDownloaded,
      isExpired: options.isExpired,
      message: options.message,
      createdAt: options.createdAt,
      availableUntil: options.availableUntil,
      shareMethod: options.shareMethod,
      recipients: options.recipients,
      fromEmail: options.fromEmail,
    }

    let hasDetails = false
    Object.keys(this.details).forEach(k => {
      if (typeof this.details[k] !== "undefined") {
        hasDetails = true
      }
    })

    if (!hasDetails) {
      delete this.details
    }

    // save passed options object in private field for easy copy function
    this.#_options = options
  }

  getTotalFileCount() {
    return this.fileCount
  }
  getTotalFileSize() {
    return this.fileSize
  }
  getProgress() {
    return this.progress
  }
  isUploadCancelled() {
    return this.isCancelled
  }
  isUploading() {
    return this._isUploading
  }
  isUploadFinished() {
    return this.isFinished
  }
  isQueued() {
    return this._isQueued
  }
  copy(newOptions) {
    return new TransferData({ ...this.#_options, ...newOptions })
  }
}
