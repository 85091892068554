import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const REQUEST_REGIONS = "region/REQUEST_REGIONS"
export const REQUEST_REGIONS_FINISHED = "region/REQUEST_REGIONS_FINISHED"
export const SELECT_REGION = "region/SELECT_REGION"
export const FASTEST_REGION_COMPUTED = "region/FASTEST_REGION_COMPUTED"

export const requestRegions = () => {
  return {
    type: REQUEST_REGIONS,
  }
}

export const finishRequestRegions = (regions, error) => {
  return {
    type: REQUEST_REGIONS_FINISHED,
    error: !!error,
    payload: serializeError(error) || regions,
  }
}

export const selectRegion = region => {
  return {
    type: SELECT_REGION,
    payload: region,
  }
}

export const computeFastestRegion = region => {
  return {
    type: FASTEST_REGION_COMPUTED,
    payload: region,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case REQUEST_REGIONS_FINISHED:
      if (isError(action)) {
        return { ...state, isInvalidRegion: true, hasRequestedRegions: true }
      } else {
        const { fastest, available, all } = action.payload
        return {
          ...state,
          fastest,
          selected: fastest,
          available,
          all,
          hasRequestedRegions: true,
        }
      }
    case SELECT_REGION:
      return { ...state, selected: action.payload }
    case FASTEST_REGION_COMPUTED:
      return {
        ...state,
        fastest: action.payload,
        selected: state.selected || action.payload,
      }
    default:
      break
  }

  return (
    state || {
      fastest: null,
      selected: null,
      available: [],
      isInvalidRegion: false,
      hasRequestedRegions: false,
    }
  )
}
