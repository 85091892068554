import { isFSA } from "../../helpers/flux-standard-action"
import { CANCEL_ADD_FILES } from "./upload"

export const DROP_FILES_REQUESTED = "filedrop/DROP_FILES_REQUESTED"
export const DROP_FILES_FINISHED = "filedrop/DROP_FILES_FINISHED"
export const CANCEL_DROP_FILES_REQUESTED =
  "filedrop/CANCEL_DROP_FILES_REQUESTED"
export const CANCEL_DROP_FILES_FINISHED = "filedrop/CANCEL_DROP_FILES_FINISHED"
export const DROP_FILES_PROGRESS = "filedrop/DROP_FILES_PROGRESS"
export const ADD_FILES_FINISHED = "filedrop/ADD_FILES_FINISHED"

export const requestDropFiles = dataTransfer => {
  return {
    type: DROP_FILES_REQUESTED,
    payload: dataTransfer,
  }
}

export const finishDropFiles = (files, totalFileCount, totalFileSize) => {
  return {
    type: DROP_FILES_FINISHED,
    payload: { files, totalFileCount, totalFileSize },
  }
}

export const finishAddFiles = () => {
  return {
    type: ADD_FILES_FINISHED,
  }
}

export const cancelDropFiles = () => {
  return {
    type: CANCEL_DROP_FILES_REQUESTED,
  }
}

export const finishCancelDropFiles = () => {
  return {
    type: CANCEL_DROP_FILES_FINISHED,
  }
}

export const dropFilesProgress = (totalFileCount, totalFileSize) => {
  return {
    type: DROP_FILES_PROGRESS,
    payload: { totalFileCount, totalFileSize },
  }
}

const initialState = {
  totalFileCount: 0,
  totalFileSize: 0,
  inProgress: false,
  isDropping: false,
  isProcessing: false,
}

export const reducer = (state = initialState, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case DROP_FILES_REQUESTED: {
      return {
        ...state,
        isDropping: true,
        inProgress: true,
      }
    }
    case CANCEL_DROP_FILES_FINISHED: {
      return {
        ...state,
        isDropping: false,
        inProgress: false,
      }
    }
    case DROP_FILES_FINISHED: {
      const { totalFileCount, totalFileSize } = action.payload
      return {
        ...state,
        isDropping: false,
        isProcessing: true,
        totalFileCount,
        totalFileSize,
      }
    }
    case DROP_FILES_PROGRESS: {
      const { totalFileCount, totalFileSize } = action.payload
      return {
        ...state,
        totalFileCount,
        totalFileSize,
      }
    }
    case CANCEL_ADD_FILES:
    case ADD_FILES_FINISHED: {
      return {
        ...state,
        isProcessing: false,
        inProgress: false,
      }
    }
    default:
      return state
  }
}
