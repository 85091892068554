import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { window, exists } from "browser-monads"

export const DomainInfoContext = React.createContext()

export const useDomainInfo = () => useContext(DomainInfoContext)

const DomainInfoProvider = ({ children }) => {
  const location = window.location

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const site = data.site

  const canonicalUrl = new URL(`${site.siteMetadata.siteUrl}`)

  // This is a hack so the loginservice knows the standard domain
  // useDomainInfo() hook should be used instead in non-legacy code
  useEffect(() => {
    window.standardDomain = canonicalUrl.hostname
  }, [canonicalUrl.hostname])

  return (
    <DomainInfoContext.Provider
      value={{
        origin: exists(location.origin) ? location.origin : site.siteMetadata.siteUrl,
        canonical: {
          origin: site.siteMetadata.siteUrl,
          hostname: canonicalUrl.hostname,
        },
        onCustomDomain: location.hostname !== canonicalUrl.hostname,
      }}
    >
      {children}
    </DomainInfoContext.Provider>
  )
}

export default DomainInfoProvider
