import React, { useContext } from "react"
import { shuffle } from "lodash"
import imageJson from "../data/background-images.json"

export const ShuffledImageContext = React.createContext()
export const useShuffledImages = () => useContext(ShuffledImageContext)

export const shuffledImages = shuffle(imageJson).map(
  img => img.src
)

const ShuffledImageProvider = ({ children }) => {
  return (
    <ShuffledImageContext.Provider
      value={{
        shuffledImages,
      }}
    >
      {children}
    </ShuffledImageContext.Provider>
  )
}

export default ShuffledImageProvider
