import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"
import { LOGIN_FINISHED, LOGOUT_FINISHED } from "./login"
import { CURRENT_UPLOAD_ID } from "./timeline"

export const FETCH_TRANSFER_REQUESTED = "transfer/FETCH_TRANSFER_REQUESTED"
export const FETCH_TRANSFER_FINISHED = "transfer/FETCH_TRANSFER_FINISHED"
export const DELETE_UPLOAD_REQUESTED = "transfer/DELETE_UPLOAD_REQUESTED"
export const DELETE_UPLOAD_FINISHED = "transfer/DELETE_UPLOAD_FINISHED"

export const requestFetchTransfer = (shortUrl, type, language) => {
  return {
    type: FETCH_TRANSFER_REQUESTED,
    payload: {
      shortUrl,
      type,
      language,
    },
  }
}

export const finishFetchTransfer = (shortUrl, type, response, error) => {
  return {
    type: FETCH_TRANSFER_FINISHED,
    error: !!error,
    payload: {
      shortUrl,
      type,
      result: serializeError(error) || response,
    },
  }
}

export const requestDeleteUpload = upload => {
  return {
    type: DELETE_UPLOAD_REQUESTED,
    payload: upload,
  }
}

export const finishDeleteUpload = (shortUrl, response, error) => {
  return {
    type: DELETE_UPLOAD_FINISHED,
    error: response?.result !== "ok",
    payload: {
      shortUrl,
      result: response?.result,
      error: serializeError(error),
    },
  }
}

function resetTransfers(state) {
  const upload = {}
  upload[CURRENT_UPLOAD_ID] = { isInvalidUrl: true }
  return {
    ...state,
    upload,
    download: {},
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case FETCH_TRANSFER_FINISHED: {
      const { shortUrl, type, result } = action.payload

      const transfersCopy = { ...state[type] }

      if (isError(action) || !result) {
        transfersCopy[shortUrl] = { isInvalidUrl: true }
      } else {
        transfersCopy[shortUrl] = result
      }

      const nextState = { ...state }
      nextState[type] = transfersCopy

      return nextState
    }
    case LOGIN_FINISHED:
      return resetTransfers(state)
    case LOGOUT_FINISHED:
      return resetTransfers(state)
    case DELETE_UPLOAD_FINISHED: {
      if (!isError(action)) {
        const { shortUrl } = action.payload

        const deletedTransfer = state.upload[shortUrl]

        if (deletedTransfer) {
          const transfersCopy = { ...state.upload }
          const nextTransfer = { ...transfersCopy[shortUrl] }
          nextTransfer.isDeleted = true
          transfersCopy[shortUrl] = nextTransfer

          return { ...state, upload: transfersCopy }
        }
      }
      break
    }
    default:
      break
  }

  return state || resetTransfers({})
}
