import React from "react"
import { withProvider } from "../state/withProvider"
import ShuffledImageProvider from "./ShuffledImageProvider"
import DomainInfoProvider from "./DomainInfoProvider"

const RootElementWrapper = ({ element }) => {
  return (
    <DomainInfoProvider>
      <ShuffledImageProvider>{withProvider({ element })}</ShuffledImageProvider>
    </DomainInfoProvider>
  )
}

export default RootElementWrapper
