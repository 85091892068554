import React, { useContext } from "react"

export const PagePropsContext = React.createContext()

export const usePageProps = () => useContext(PagePropsContext)

const PagePropsProvider = ({ pageProps, children }) => {
  return (
    <PagePropsContext.Provider value={pageProps}>{children}</PagePropsContext.Provider>
  )
}

export default PagePropsProvider
