import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const CHECK_DOMAIN_REQUESTED =
  "domaincustomisation/CHECK_DOMAIN_REQUESTED"
export const CHECK_DOMAIN_FINISHED = "domaincustomisation/CHECK_DOMAIN_FINISHED"
export const RESET_DOMAIN_CHECK = "domaincustomisation/RESET_DOMAIN_CHECK"

export const GET_DOMAIN_REQUESTED = "domaincustomisation/GET_DOMAIN_REQUESTED"
export const GET_DOMAIN_FINISHED = "domaincustomisation/GET_DOMAIN_FINISHED"

export const SAVE_DOMAIN_REQUESTED = "domaincustomisation/SAVE_DOMAIN_REQUESTED"
export const SAVE_DOMAIN_FINISHED = "domaincustomisation/SAVE_DOMAIN_FINISHED"

export const requestCheckDomain = domain => {
  return {
    type: CHECK_DOMAIN_REQUESTED,
    payload: domain,
  }
}

export const finishCheckDomain = (result, error) => {
  return {
    type: CHECK_DOMAIN_FINISHED,
    error: !!error,
    payload: serializeError(error) || result,
  }
}

export const resetDomainCheck = () => {
  return {
    type: RESET_DOMAIN_CHECK,
  }
}

export const requestGetDomain = () => {
  return {
    type: GET_DOMAIN_REQUESTED,
  }
}

export const finishGetDomain = (domain, error) => {
  return {
    type: GET_DOMAIN_FINISHED,
    error: !!error,
    payload: serializeError(error) || domain,
  }
}

export const requestSaveDomain = domain => {
  return {
    type: SAVE_DOMAIN_REQUESTED,
    payload: domain,
  }
}

export const finishSaveDomain = (domain, error) => {
  return {
    type: SAVE_DOMAIN_FINISHED,
    error: !!error,
    payload: serializeError(error) || domain,
  }
}

const initialState = {
  domain: "",
  domainCheck: {
    domain: "",
    isCheckingDomain: false,
    isInvalidDomain: false,
    isDomainAvailable: false,
  },
}

export const reducer = (state = initialState, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case CHECK_DOMAIN_REQUESTED:
      return {
        ...state,
        domainCheck: {
          ...initialState.domainCheck,
          domain: action.payload,
          isCheckingDomain: true,
        },
      }
    case CHECK_DOMAIN_FINISHED: {
      if (isError(action)) {
        return {
          ...state,
          domainCheck: initialState.domainCheck,
        }
      }
      const { domain, isDomainAvailable } = action.payload
      return {
        ...state,
        domainCheck: {
          domain,
          isCheckingDomain: false,
          isDomainAvailable,
          isInvalidDomain: !isDomainAvailable,
        },
      }
    }
    case RESET_DOMAIN_CHECK:
      return {
        ...state,
        domainCheck: initialState.domainCheck,
      }
    case GET_DOMAIN_FINISHED:
      if (!isError(action)) {
        return {
          ...state,
          domain: action.payload || "",
        }
      }
      break
    case SAVE_DOMAIN_FINISHED:
      if (!isError(action)) {
        return {
          ...initialState,
          domain: action.payload || "",
        }
      }
      break
    default:
      break
  }

  return state
}
