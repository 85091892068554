import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const CLEAR_BACKGROUND_REQUESTED =
  "bgcustomisation/CLEAR_BACKGROUND_REQUESTED"
export const CLEAR_BACKGROUND_FINISHED =
  "bgcustomisation/CLEAR_BACKGROUND_FINISHED"

export const UPLOAD_BACKGROUND_REQUESTED =
  "bgcustomisation/UPLOAD_BACKGROUND_REQUESTED"
export const UPLOAD_BACKGROUND_FINISHED =
  "bgcustomisation/UPLOAD_BACKGROUND_FINISHED"

export const GET_BACKGROUND_REQUESTED =
  "bgcustomisation/GET_BACKGROUND_REQUESTED"
export const GET_BACKGROUND_FINISHED = "bgcustomisation/GET_BACKGROUND_FINISHED"

export const requestClearBackground = () => {
  return {
    type: CLEAR_BACKGROUND_REQUESTED,
  }
}

export const finishClearBackground = error => {
  return {
    type: CLEAR_BACKGROUND_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const requestUploadBackground = options => {
  return {
    type: UPLOAD_BACKGROUND_REQUESTED,
    payload: options,
  }
}

export const finishUploadBackground = error => {
  return {
    type: UPLOAD_BACKGROUND_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const requestGetBackground = () => {
  return {
    type: GET_BACKGROUND_REQUESTED,
  }
}

export const finishGetBackground = (backgroundInfo, error) => {
  return {
    type: GET_BACKGROUND_FINISHED,
    error: !!error,
    payload: serializeError(error) || backgroundInfo,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case CLEAR_BACKGROUND_FINISHED:
      if (!isError(action)) {
        return { ...state, backgroundImage: null }
      }
      break
    case GET_BACKGROUND_FINISHED:
      if (!isError(action)) {
        const { url, crop } = action.payload
        return { ...state, backgroundImage: { url, crop } }
      }
      break
    default:
      break
  }

  return (
    state || {
      backgroundImage: null,
    }
  )
}
