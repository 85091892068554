import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"

export const SUBSCRIPTIONPROVIDER_CHARGEBEE = "chargebee"

export const REQUEST_PLANS = "subscription/REQUEST_PLANS"
export const REQUEST_PLANS_FINISHED = "subscription/REQUEST_PLANS_FINISHED"
export const INIT_CHARGEBEE = "subscription/INIT_CHARGEBEE"
export const INIT_CHARGEBEE_FINISHED = "subscription/INIT_CHARGEBEE_FINISHED"
export const REQUEST_SUBSCRIPTION = "subscription/REQUEST_SUBSCRIPTION"
export const REQUEST_SUBSCRIPTION_FINISHED =
  "subscription/REQUEST_SUBSCRIPTION_FINISHED"
export const OPEN_SELF_SERVICE_PORTAL = "subscription/OPEN_SELF_SERVICE_PORTAL"

export const getPlans = () => {
  return {
    type: REQUEST_PLANS,
  }
}

export const finishGetPlans = (plans, error) => {
  return {
    type: REQUEST_PLANS_FINISHED,
    error: !!error,
    payload: serializeError(error) || plans,
  }
}

export const initChargebee = () => {
  return {
    type: INIT_CHARGEBEE,
  }
}

export const finishInitChargebee = error => {
  return {
    type: INIT_CHARGEBEE_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const subscribe = (plan, language) => {
  return {
    type: REQUEST_SUBSCRIPTION,
    payload: { plan, language },
  }
}

export const finishSubscription = error => {
  return {
    type: REQUEST_SUBSCRIPTION_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const openSelfServicePortal = () => {
  return {
    type: OPEN_SELF_SERVICE_PORTAL,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case REQUEST_PLANS_FINISHED:
      if (!isError(action)) {
        return { ...state, plans: action.payload || [] }
      }
      break
    case INIT_CHARGEBEE_FINISHED:
      if (!isError(action)) {
        const chargebee = { ...state.chargebee, isInitialized: true }
        return { ...state, chargebee }
      }
      break
    default:
      break
  }
  return state || { plans: [], chargebee: { isInitialized: false } }
}
