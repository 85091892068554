import { isFSA } from "../../helpers/flux-standard-action"

export const SAGAS_INITIALIZED = "ready/SAGAS_INITIALIZED"

export const initializeSagas = () => {
  return {
    type: SAGAS_INITIALIZED,
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case SAGAS_INITIALIZED:
      return { ...state, sagasInitialized: true }
    default:
      break
  }

  return (
    state || {
      sagasInitialized: false,
    }
  )
}
