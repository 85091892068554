import { isError, isFSA } from "../../helpers/flux-standard-action"
import { serializeError } from "../../helpers/error"
import config from "../../config"

export const LOGIN_REQUESTED = "login/LOGIN_REQUESTED"
export const SOCIAL_LOGIN_REQUESTED = "login/SOCIAL_LOGIN_REQUESTED"
export const TOKEN_LOGIN_REQUESTED = "login/TOKEN_LOGIN_REQUESTED"
export const TOKEN_RESET_REQUESTED = "login/TOKEN_RESET_REQUESTED"
export const APPLE_LOGIN_REQUESTED = "login/APPLE_LOGIN_REQUESTED"
export const LOGIN_FINISHED = "login/LOGIN_FINISHED"
export const LOGOUT_REQUESTED = "login/LOGOUT_REQUESTED"
export const LOGOUT_FINISHED = "login/LOGOUT_FINISHED"
export const SET_SENDER_EMAIL = "login/SET_SENDER_EMAIL"
export const RESET_LOGIN_ERROR = "login/RESET_LOGIN_ERROR"
export const CHECK_REFRESH_TOKEN = "login/CHECK_REFRESH_TOKEN"
export const SET_FULL_NAME = "login/SET_FULL_NAME"
export const RESET_PASSWORD_REQUESTED = "login/RESET_PASSWORD_REQUESTED"

export const requestLogin = (username, password) => {
  return {
    type: LOGIN_REQUESTED,
    payload: {
      username,
      password,
    },
  }
}

export const requestSocialLogin = serviceid => {
  return {
    type: SOCIAL_LOGIN_REQUESTED,
    payload: serviceid,
  }
}

export const requestTokenLogin = (token, pathname) => {
  return {
    type: TOKEN_LOGIN_REQUESTED,
    payload: {
      token,
      pathname,
    },
  }
}

export const requestTokenReset = pathname => {
  return {
    type: TOKEN_RESET_REQUESTED,
    payload: {
      pathname,
    },
  }
}

export const requestAppleLogin = data => {
  return {
    type: APPLE_LOGIN_REQUESTED,
    payload: data,
  }
}

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUESTED,
  }
}

export const finishLogin = (userinfo, error) => {
  return {
    type: LOGIN_FINISHED,
    error: !!error,
    payload: serializeError(error) || userinfo,
  }
}

export const finishLogout = error => {
  return {
    type: LOGOUT_FINISHED,
    error: !!error,
    payload: serializeError(error),
  }
}

export const setSenderEmail = email => {
  return {
    type: SET_SENDER_EMAIL,
    payload: {
      email,
    },
  }
}

export const resetLoginError = () => {
  return {
    type: RESET_LOGIN_ERROR,
  }
}

export const checkRefreshToken = () => {
  return {
    type: CHECK_REFRESH_TOKEN,
  }
}

export const setFullName = payload => {
  return {
    type: SET_FULL_NAME,
    payload,
  }
}

export const requestResetPassword = (email, language) => {
  return {
    type: RESET_PASSWORD_REQUESTED,
    payload: { email, language },
  }
}

export const reducer = (state, action) => {
  if (!isFSA(action))
    throw new Error(`Non-standard action received: ${action.type}`)

  switch (action.type) {
    case LOGIN_FINISHED:
      if (isError(action)) {
        return {
          ...state,
          hasAuthFailed: true,
        }
      } else {
        const userinfo = action.payload
        if (userinfo) {
          return {
            ...state,
            userinfo,
            isAuthenticated: true,
            hasAuthFailed: false,
            hasSubscription:
              (!userinfo.restriction &&
                userinfo.subscriptionType &&
                config.subscription.plans.includes(
                  userinfo.subscriptionType
                )) ||
              false,
            isResettingPassword: userinfo.restriction === "reset-password",
          }
        }
        break
      }
    case LOGOUT_FINISHED:
      return {
        ...state,
        userinfo: { email: state.userinfo?.email },
        isAuthenticated: false,
        hasSubscription: false,
        isResettingPassword: false,
      }
    case SET_SENDER_EMAIL:
      if (state.isAuthenticated !== true) {
        return {
          ...state,
          userinfo: { ...state.userinfo, email: action.payload.email },
        }
      }
      break
    case RESET_LOGIN_ERROR:
      return { ...state, hasAuthFailed: false }
    case SET_FULL_NAME:
      return {
        ...state,
        userinfo: { ...state.userinfo, ...action.payload },
      }
    default:
      break
  }

  return (
    state || {
      isAuthenticated: false,
      userinfo: { email: "" },
      hasAuthFailed: false,
      hasSubscription: false,
      isResettingPassword: false,
    }
  )
}
